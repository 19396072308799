<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="6" class="header-editor-col">
        <HelpFormInputVue
          dark
          v-model="form.code"
          :rules="serviceCodeRules"
          label="Service Code"
          :helpText="'This is unique service code for the selected service type.'"
          required
        ></HelpFormInputVue>
        <HelpFormInputVue
          dark
          v-model="form.name"
          :rules="serviceNameRules"
          label="Service Name"
          :helpText="'This is a service name that will be used to recognize the service.'"
          required
        ></HelpFormInputVue>
        <HelpFormInputVue
          dark
          v-model="form.action_btn_text"
          :rules="serviceBtnRules"
          label="Action Button Text"
          :helpText="'This text will be used to highlight Go-to-Price action'"
          required
        ></HelpFormInputVue>

        <SelectorServicesVue
          dark
          v-model="form.prevService"
          autoload
          label="Previous Recommended Service"
          helpText="This is a service that will be recommended as the previous step. The customer may take a look on it before start current service"
          return-object
          @onItemClick="(s)=>$emit('onServiceClick',s)"
        ></SelectorServicesVue>

        <SelectorServicesVue
          dark
          v-model="form.nextService"
          autoload
          label="Next Recommended Service"
          helpText="This is a service that will be recommended as the next step. The customer may take a look on it after current service"
          return-object
          @onItemClick="(s)=>$emit('onServiceClick',s)"
        ></SelectorServicesVue>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="6" class="header-editor-col">
        <HelpFormInputVue
          dark
          :rows="4"
          v-model="form.short_description"
          :rules="serviceDescriptionRules"
          label="Short Short Description"
          :helpText="'This text will be displayed under service name as a main service description'"
          required
          :textarea="true"
        ></HelpFormInputVue>
        <HelpFormInputVue
          dark
          :rows="4"
          v-model="form.brief"
          :rules="serviceDescriptionRules"
          label="Short Brief Overview"
          :helpText="'This text should provide service goal in a few words.'"
          required
          :textarea="true"
        ></HelpFormInputVue>
      </v-col>
    </v-row>
  </v-form>
</template>


<script>
import SelectorServicesVue from "../../../atoms/common/autocompletes/SelectorServices.vue";
import HelpFormInputVue from "../../../atoms/common/inputs/HelpFormInput.vue";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Form
      pValid: this.valid,
      form: this.value,

      // ======rules
      serviceLevelRules: [(v) => !!v || "Service Level is required"],
      serviceNameRules: [(v) => !!v || "Service Name is required"],
      serviceCodeRules: [(v) => !!v || "Service Name is required"],
      serviceBtnRules: [(v) => !!v || "Action Button Text is required"],
      serviceDescriptionRules: [(v) => !!v || "Description is required"],
      select: null,
      items: [
        "A - technical",
        "I -idea level",
        "M - managing",
        "Something else",
      ],
      checkbox: false,
    };
  },
  components: {
    HelpFormInputVue,
    SelectorServicesVue,
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    value(newVal) {
      this.form = newVal;
    },
    form: {
      handler(newVal) {
        this.form.previous_service_id = newVal?.prevService?.id;
        this.form.next_service_id = newVal?.nextService?.id;

        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>


<style lang="scss" scoped>
.header-editor-col{
  display: flex;
  flex-direction: column;
  min-width: 300px;
  flex-grow: 1;
  max-width: 100%;
}
</style>